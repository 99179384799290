import styled from '@emotion/styled'

export const RollButtonContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;

  .btn-container {
    height: 102px;
  }
`;
